import './styles/main.css';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import { getCourses, postCourse, Course, CourseSummary } from './common/scripts/courses';
import { getUserData, User } from './common/scripts/users';
import { getPurchases } from './common/scripts/purchases'
import LoadingScreen from './common/LoadingScreen';
import UpperBar from './common/UpperBar';
import './styles/main.css';
import './styles/courses.css';
import './common/styles/CourseDescription.css'
import 'react-tabs/style/react-tabs.css'
import { getProfessors, deleteProfessor, Professor, createProfessor } from './common/scripts/professors';
import ProfessorCard from './common/ProfessorCard';
import ProfessorInfo from './common/ProfessorInfo';
import LogMain from './common/LogMain';
import LogInfo from './common/LogInfo';
import { useAuth } from '../context/AuthProvider';
import { clearServerCache } from './common/scripts/cache';
import { useTranslation } from 'react-i18next';
import * as Firestore from "firebase/firestore"


type props = {
    logOut: () => Promise<void>
}
type ClickHandler = (courseid: string) => (e: React.MouseEvent) => void;

export default function Information(props: props) {
    const [professors, setProfessorsData] = useState<Professor[]>([]);
    const [selectedProfessor, setSelectedProfessor] = useState<Professor>();
    const professorInfo = useRef<HTMLDivElement>(null);
    const logInfo = useRef<HTMLDivElement>(null);
    const scrollElement = useRef<HTMLDivElement>(null);
    const arrowRight = useRef<HTMLDivElement>(null);
    const arrowLeft = useRef<HTMLImageElement>(null);
    let [leftArrowStyle, setLeftArrowStyle] = useState({ display: "none" });
    let [rightArrowStyle, setRightArrowStyle] = useState({ display: "flex" });
    const [courses, setCoursesData] = useState<CourseSummary[]>([]);
    const [purchases, setPurchases] = useState<string[]>([]);
    const [bussy, setBussy] = useState(true);
    const [done, setDone] = useState(false);
    const { loading, user, admin } = useAuth();
    const { t } = useTranslation();
    let table = CreateTable(8, 11);
    let navigate = useNavigate();
    const clickHandler: ClickHandler = (courseid) => (e) => {
        navigate('/courses/' + courseid);
    };
    let activeCourses = courses.filter(course => course.enabled);
    let publishedCourses = activeCourses.filter(course => course.published);
    let ownedCourses = !admin ? activeCourses.filter(course => purchases.includes(course.id)) : courses;

    useEffect(() => {
        const fetch = async () => {
            downloadProfessors();
            if (user) setPurchases(await getPurchases(user.uid));
            const coursesData = await getCourses();
            setCoursesData(coursesData);
            setDone(true);
            setBussy(false);
        }
        if (courses.length === 0) fetch();
    }, [courses, navigate])

    const scrollToRight = () => {
        scrollElement.current!.scrollLeft += window.screen.width / 3;
    }

    const scrollToLeft = () => {
        scrollElement.current!.scrollLeft -= window.screen.width / 3;
    }

    const diseableButton = () => {
        if (scrollElement.current) {
            const max = (scrollElement.current.scrollWidth - 20) - scrollElement.current.clientWidth;
            scrollElement.current.scrollLeft >= max
                ? (arrowRight.current ? setRightArrowStyle({ display: "none" }) : console.log("no display"))
                : (arrowRight.current ? setRightArrowStyle({ display: "flex" }) : console.log("no display"));
            scrollElement.current.scrollLeft <= 20
                ? (arrowLeft.current ? setLeftArrowStyle({ display: "none" }) : console.log("no display"))
                : (arrowLeft.current ? setLeftArrowStyle({ display: "flex" }) : console.log("no display"));
        }
    }

    const downloadProfessors = async () => {
        setProfessorsData(await getProfessors());
    }

    const showProfessor = (professor: Professor) => {
        setSelectedProfessor(professor);
        professorInfo.current!.style.display = "flex";
    }

    const addProfessor = async () => {
        try {
            if (!bussy) {
                setBussy(true);
                let newProfessor: Professor = { id: "", name: "nuevo profesor", special: "especialidad", sinapsis: "", thumbnail: "" };
                newProfessor.id = await createProfessor(newProfessor);
                setProfessorsData(professors.concat(newProfessor));
                setBussy(false);
            }
        }
        catch (e) {
            setBussy(false);
            console.log(e);
        }
    }

    const removeProfessor = async (professor: Professor) => {
        try {
            if (!bussy && window.confirm('¿Esta seguro que desea borrar el registro para el profesor <' + professor.name + '>?')) {
                setBussy(true);
                await deleteProfessor(professor.id);
                setProfessorsData(professors.filter(professor_ => professor_.id !== professor.id));
                setBussy(false);
            }
        }
        catch
        {
            setBussy(false);
            console.log("no se pudo eliminar profesor");
        }
    }

    const addCourse = async () => {
        try {
            if (!bussy) {
                setBussy(true);
                console.log("subiendo curso");
                const newCourse: Course = {
                    description: "",
                    thumbnail: "",
                    videoThumbnail: "",
                    video: "",
                    signupForm: "",
                    title: "nuevo curso",
                    subtitle: "",
                    professors: [],
                    published: false,
                    price: 0,
                    currency: 0,
                    time: 0,
                    teachersCount: 0,
                    studentCount: 0,
                    start_date: Firestore.Timestamp.now(),
                    end_date: Firestore.Timestamp.now(),
                    levels: [{ title: "nuevo modulo", visible: true, cases: [{ title: "nuevo caso", spawn: 0 }] }],
                    descriptionBlocks: []
                };
                setCoursesData(courses.concat({
                    id: await postCourse(newCourse),
                    thumbnail: newCourse.thumbnail,
                    videoThumbnail: newCourse.videoThumbnail,
                    start_date: newCourse.start_date,
                    end_date: newCourse.end_date,
                    studentCount: newCourse.studentCount,
                    time: newCourse.time,
                    title: newCourse.title,
                    published: newCourse.published
                }));
                setBussy(false);
            }
        }
        catch
        {
            setBussy(false);
            console.log("no se pudo subir el curso");
        }
    }

    const refresh = async () => {
        await clearServerCache(user?.uid!);
        setCoursesData([]);
    }

    return (
        <>
            <UpperBar logOut={props.logOut} navigate={navigate} />
            {!done ? (<LoadingScreen />) : (
                <>
                    <div className='area'>
                        <div className='welcomeArea'>
                            <br></br>
                            <div>{t("Courses")}</div>
                            <div className='lastcourse'>
                                <div className='videoThumbnail'>
                                    <div onClick={clickHandler(publishedCourses[9].id)} className='course-button'>
                                        {publishedCourses.length > 0 && (publishedCourses[9].videoThumbnail ? (publishedCourses[8].videoThumbnail.endsWith(".mp4") && (
                                            <video width="100%" height="auto" autoPlay muted loop>
                                                <source src={publishedCourses[9].videoThumbnail} type="video/mp4"></source>
                                            </video>
                                        )) : (publishedCourses[9].thumbnail && (
                                            <img src={publishedCourses[9].thumbnail} width="100%" height="100%" alt="Thumbnail" style={{ borderRadius: "25PX" }} />

                                        )))}
                                        {publishedCourses.length > 0 && (
                                            <div className='text-card'>
                                                <div>{publishedCourses[9].title}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

function CreateTable(x: number, y: number) {
    let table: string[][] = [];
    for (var i = 0; i < x; i++) {
        table[i] = [];
        for (var j = 0; j < y; j++)
            table[i][j] = i + "," + j;
    }
    return table;
}