import { useOverlay } from "../../context/OverlayProvider";

type props = {
    children: string | JSX.Element | React.ReactNode
}

function OverlayWindow(props: props) {
    const [, setOverlay] = useOverlay();
    return (
        <div style={{"backgroundColor": "white", "minWidth": "150px", "minHeight": "100px", "maxWidth": "500px", "display": "flex", "borderRadius": "20px", "justifyContent": "center", "alignItems": "center", "flexDirection": "column", "position": "relative", "boxShadow": "10px 10px 10px #3a3a3a"}}>
            <img onClick={() => setOverlay(overlay => {return {...overlay, display: "none"}})} src="/img/x-icon.png" style={{"cursor": "pointer","position": "absolute", "top": "15px", "right": "15px", "width": "15px", "height": "15px"}}/>
            {props.children}
        </div>
    );
}

export default OverlayWindow;