import './App.css';
import './components/common/styles/Input.css'
import en from './assets/translations/en.json'
import es from './assets/translations/es.json'
import Form from './components/common/Form'
import Main from './components/Main'
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import { useRef, useEffect } from 'react';
import { getIdToken, sendPasswordResetEmail, UserCredential } from 'firebase/auth';
import Courses from './components/Courses';
import Play from './components/Play'
import { auth } from './components/config/firebaseSetup';
import { useAuth } from './context/AuthProvider';
import { Layout } from './components/layout/Layout';
import { RequireAuth } from './components/auth/RequireAuth';
import { initReactI18next, useTranslation } from 'react-i18next';
import Information from './components/Information';
import News from './components/News';
import Support from './components/Support';
import i18next from 'i18next';
import { useLanguage } from './context/LanguageProvider';

declare global {
  interface Window { session: UserCredential; }
}

function App() {
  const authContext = useAuth();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordVRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [ language ] = useLanguage();
  const { t } = useTranslation();

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language, i18next])

  const sendPasswordResetLink = async () => {
    await sendPasswordResetEmail(auth, emailRef.current!.value);
    navigate("/login");
  };

  const login = async () => {
    await authContext.login(emailRef.current!.value, passwordRef.current!.value);
  }

  const signup = async () => {
    await authContext.signup(emailRef.current!.value, passwordRef.current!.value);
  }

  const logout = async () => {
    await authContext.logout();
  }

  const refreshToken = async () => {
    if (user) return await getIdToken(user, true);
    else return undefined;
  }

  return (
    <div className="App">
      <>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={
              <RequireAuth>
                <Main logOut={logout} />
              </RequireAuth>
            } />

            <Route path='/passwordrecovery' element={
              <Form
                title={t("ResetPassword")}
                type="recovery"
                emailRef={emailRef}
                passwordRef={passwordRef}
                passwordVRef={passwordVRef}
                event={sendPasswordResetLink}
              />
            } />
            <Route path='/login' element={
              <Form
                title={t("WelcomeBack")}
                type="login"
                emailRef={emailRef}
                passwordRef={passwordRef}
                passwordVRef={passwordVRef}
                event={login}
              />
            } />
            <Route path='/signup' element={
              <Form
                title={t("Register")}
                type="signup"
                emailRef={emailRef}
                passwordRef={passwordRef}
                passwordVRef={passwordVRef}
                event={signup}
              />
            } />
            <Route path='/courses/:courseid' element={<Courses logOut={logout} />} />
            <Route path='/information' element={<Information logOut={logout} />} />
            <Route path='/news' element={<News logOut={logout} />} />
            <Route path='/support' element={<Support logOut={logout} />} />
            <Route path='/play/:courseid/:levelindex/:caseindex' element={
              <RequireAuth>
                <Play refreshToken={refreshToken} />
              </RequireAuth>
            } />
          </Route>
        </Routes>
      </>
    </div>
  );
}

i18next
    .use(initReactI18next)
    .init({
        resources: {
            en: {
              translation: en
            },
            es: {
              translation: es
            },
        },
        lng: "es",
        fallbackLng: "es",
        interpolation: {
            escapeValue: false
        }
    })

export default App;