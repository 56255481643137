import './styles/main.css';
import './styles/courses.css';
import './common/styles/CourseDescription.css'
import React, { useEffect, useState, useRef } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import { getCourse, postModule, postCase, deleteModule, Course, Level, Case, deleteCase, TextBlock } from './common/scripts/courses';
import { getProfessors, deleteProfessorFromCourse, Professor, addProfessorOnCourse } from './common/scripts/professors';
import { getUserData, User } from './common/scripts/users';
import { getPurchaseStatus } from './common/scripts/purchases';
import { getUserGrades } from './common/scripts/grades';
import * as FieldEditor from './common/scripts/fields';
import ProfessorCard from './common/ProfessorCard';
import ProfessorInfo from './common/ProfessorInfo';
import Module from './common/Module';
import UpperBar from './common/UpperBar'
import LoadingScreen from './common/LoadingScreen';
import Publish from './common/Publish';
import CourseTop from './common/CourseTop';
import CourseQuick from './common/CourseQuick';
import Design from './common/Design';
import Students, { TableType, GradeRecord } from './common/Students';
import AddUser from './common/AddUser';
import LogMain from './common/LogMain';
import LogInfo from './common/LogInfo';
import { useAuth } from '../context/AuthProvider';
import { useOverlay } from '../context/OverlayProvider';
import OverlayWindow from './overlay/OverlayWindow';
import ProfessorAdd from './common/ProfessorAdd';
import { useTranslation } from 'react-i18next';
import CourseInfo from './common/CourseInfo';
import CourseHQs from './common/CourseHQs';
type props = {
    logOut: () => Promise<void>
}

export default function Courses(props: props) {
const { courseid } = useParams();
    const [course, setCourseData] = useState<Course | null>(null);
    const [selectedProfessor, setSelectedProfessor] = useState<Professor>();
    const logInfo = useRef<HTMLDivElement>(null);
    const [professors, setProfessorsData] = useState<Professor[]>([]);
    const [grades, setGrades] = useState<string | null>(null);
    const [gradeList, setGradeList] = useState<GradeRecord[] | null>(null);
    const [applicantList, setApplicantList] = useState<GradeRecord[] | null>(null);
    const [acceptedList, setAcceptedList] = useState<GradeRecord[] | null>(null);
    const [blocks, setBlocks] = useState<TextBlock[]>([]);
    const [done, setDone] = useState(false);
    const [bussy, setBussy] = useState(true);
    const [callLogin, setCallLogin] = useState(false);
    const [callRegister, setCallRegister] = useState(false);
    const [hasApplied, setHasApplied] = useState(false);
    const [isPurchased, setPurchaseStatus] = useState(false);
    const [isAccepted, setAcceptedStatus] = useState(false);
    const professorInfo = useRef<HTMLDivElement>(null);
    const addUser = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const { user, admin } = useAuth();
    const [, setOverlay] = useOverlay();
    const { t } = useTranslation();
    //#endregion
    let i = 0;
    let navigate = useNavigate();

    const addModule = async () => {
        try {
            if (course && !bussy) {
                setBussy(true);
                const newModule: Level = { title: t("new module"), visible: false, cases: [{ title: t("new case"), spawn: 0 }] };
                await postModule(courseid || "", newModule);
                let newLevels: Level[] = course.levels;
                newLevels.push(newModule);
                setCourseData({ ...course, levels: newLevels });
                setBussy(false);
            }
        }
        catch
        {
            setBussy(false);
            console.log("no se pudo subir el modulo");
        }
    }

    const removeModule = async (levelIndex: number) => {
        try {
            if (course && !bussy && window.confirm('¿Esta seguro que desea eliminar el modulo <' + course?.levels[levelIndex]?.title + '>?')) {
                setBussy(true);
                await deleteModule(courseid || "", levelIndex);
                let newLevels: Level[] = course.levels;
                newLevels[levelIndex] = null;
                setCourseData({ ...course, levels: newLevels });
                setBussy(false);
            }
        }
        catch
        {
            setBussy(false);
            console.log("no se pudo borrar el modulo");
        }
    }

    const removeCase = async (levelIndex: number, caseIndex: number) => {
        try {
            if (course && !bussy && window.confirm('¿Esta seguro que desea eliminar el caso <' + course?.levels[levelIndex]?.cases[caseIndex]?.title + '>?')) {
                setBussy(true);
                await deleteCase(courseid || "", levelIndex, caseIndex);
                let newLevels: Level[] = course.levels;
                newLevels[levelIndex]!.cases[caseIndex] = null;
                setCourseData({ ...course, levels: newLevels });
                setBussy(false);
            }
        }
        catch
        {
            setBussy(false);
            console.log("no se pudo borrar el caso");
        }
    }

    const addCase = async (levelIndex: number) => {
        try {
            if (course && !bussy) {
                setBussy(true);
                const newCase: Case = { title: t("new case"), spawn: 0 };
                await postCase(courseid || "", levelIndex, newCase);
                let newLevels: Level[] = course.levels;
                newLevels[levelIndex]?.cases.push(newCase);
                setCourseData({ ...course, levels: newLevels });
                setBussy(false);
            }
        }
        catch
        {
            setBussy(false);
            console.log("no se pudo subir el modulo");
        }
    }
    const removeProfessor = async (professor: Professor) => {
        try {
            if (course && !bussy) {
                setBussy(true);
                await deleteProfessorFromCourse(courseid || "", professor.id);
                let newProfessors: string[] = course.professors;
                newProfessors = newProfessors.filter(professorid => professor.id !== professorid);
                setCourseData({ ...course, professors: newProfessors });
                setBussy(false);
            }
        }
        catch (e) {
            setBussy(false);
            console.log("no se pudo eliminar profesor: " + e);
        }
    }
    const downloadGrades = async (courseid: string, uid: string) => {
        setGrades(await getUserGrades(courseid, uid));
    }

    const downloadProfessors = async () => {
        setProfessorsData(await getProfessors());
    }

    const showProfessor = (professor: Professor) => {
        setSelectedProfessor(professor);
        professorInfo.current!.style.display = "flex";
    }

    const addProfessorLocal = (id: string) => {
        setCourseData(coursed => {
            let newProfessors: string[] = coursed!.professors;
            newProfessors = [...newProfessors, id];
            console.log(newProfessors);
            return { ...coursed!, professors: newProfessors }
        });
    }

    const showAddProfessorWindow = () => {
        setOverlay(overlay => { 
            return {...overlay, display: "flex", 
            content: 
                <OverlayWindow>
                    <ProfessorAdd
                        bussy={bussy}
                        setBussy={setBussy}
                        professors={professors}
                        addProfessorLocal={addProfessorLocal}
                    />
                </OverlayWindow>
        }})
    }

    useEffect(() => {
        const fetch = async () => {
            if (courseid && user) {
                downloadGrades(courseid, user.uid);
                downloadProfessors();
                const downloadedCourse = await getCourse(courseid);
                setCourseData(downloadedCourse);
                if (downloadedCourse.descriptionBlocks) {
                    setBlocks(downloadedCourse.descriptionBlocks);
                }
                setPurchaseStatus(await getPurchaseStatus("purchases", courseid, user.uid));
                setAcceptedStatus(await getPurchaseStatus("admitted", courseid, user.uid));
                setHasApplied(await getPurchaseStatus("applicants", courseid, user.uid))
                setDone(true);
                setBussy(false);
            }
            else if (courseid) {
                downloadProfessors();
                const downloadedCourse = await getCourse(courseid);
                setCourseData(downloadedCourse);
                if (downloadedCourse.descriptionBlocks) {
                    setBlocks(downloadedCourse.descriptionBlocks);
                }
                setDone(true);
                setBussy(false);
            }
        }
        if (!course) fetch();
    })

    if (callRegister) return <Navigate to="/signup" state={{ from: location }} replace />;

    if (callLogin) return <Navigate to="/login" state={{ from: location }} replace />;

    if (done && !course?.enabled && !admin) return <Navigate to="/" replace />;

    return (
        <>
        <UpperBar logOut={props.logOut} navigate={navigate} callLogin={setCallLogin} />
            {!done ? (<LoadingScreen />) : (
                <>
                    <div className='area'>
                        <CourseTop course={course} navigate={navigate} setCallRegister={setCallRegister} isPurchased={isPurchased} isAccepted={isAccepted} hasApplied={hasApplied} setPurchaseStatus={setPurchaseStatus} />
                        <br></br>
                        <Tabs defaultIndex={0} className="course-tabs">
                            {course && course.levels && course.levels.map((level, index) => {
                                if (level && (level.visible || admin)) {
                                    i++;
                                    return (<Module
                                        isPurchased={isPurchased}
                                        level={level}
                                        key={"module-x-" + i}
                                        index={i}
                                        levelIndex={index}
                                        courseId={courseid ? courseid : ""}
                                        moduleGrades={grades ? grades[index] : {}}
                                        bussy={bussy}
                                        setBussy={setBussy}
                                        removeModule={removeModule}
                                        removeCase={removeCase}
                                        addCase={addCase}
                                    />
                                    
                                    )
                                }
                                else return (<></>)
                            })}
                        </Tabs>
                    </div>                    
                </>
            )}
        </>
    );
}