type props = {
    title: string,
    src: string
}

function RSOption(props: props) {
    return (
        <>
            <img src={props.src} height="15px" width="15px"/>
            {props.title} 
        </>
    )
}

export default RSOption;