import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useOverlay } from "../../context/OverlayProvider";
import OverlayWindow from "../overlay/OverlayWindow";
import { Course } from "./scripts/courses";
import { addStudent, deleteStudent } from "./scripts/purchases";
import { TableType } from "./Students";
import './styles/Payment.css'
import { useTranslation } from 'react-i18next';

type props = {
    course: Course,
    setPurchaseStatus: React.Dispatch<React.SetStateAction<boolean>>
}

function Payment(props: props) {
    const { courseid } = useParams();
    const { user } = useAuth();
    const { course, setPurchaseStatus } = props;
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [, setOverlay] = useOverlay();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: course.currency === 0 ? "MXN" : "USD",
            },
        });
    }, [course]);

    return (
        <div style={{ "marginTop": "15px", "padding": "0 15px", "display": "flex", "flexDirection": "column", "justifyContent": "center"}}>
            <div style={{ "display": "flex", "flexDirection": "column", "justifyContent": "center", "alignItems": "center" }}>
                <h3>{t("Your order")}</h3>
                <div style={{ "background": "#EBF2FC", "borderRadius": "10px", "padding": "15px", "display": "flex", "flexDirection": "column", "width": "95%", "marginBottom": "20px" }}>
                    <div style={{ "display": "flex", "gap": "5px" }}>
                        <div style={{ "fontWeight": "700" }}>{t("Courses: ")}</div>
                        <div>{course?.title}</div>
                    </div>
                    <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                        <div style={{ "fontWeight": "700" }}>{t("Subtitle: ")}</div>
                        <div>{course?.subtitle}</div>
                    </div>
                    <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                        <div style={{ "fontWeight": "700" }}>{t("Price: ")}</div>
                        <div>${course?.price + " " + (course.currency === 0 ? "MXN" : "USD")}</div>
                    </div>
                </div>
            </div>
            <PayPalButtons
                forceReRender={[course]}
                style={{ "color": "blue" }}
                className="custom-pay"
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: course.price.toString(),
                                currency_code: course.currency === 0 ? "MXN" : "USD"
                            }
                        }]
                    })
                }}
                onApprove={async (data, actions) => {
                    await addStudent(TableType.Students, courseid!, user?.email!);
                    setOverlay(overlay => { return { ...overlay, display: "flex", content: <OverlayWindow>{t("Successful Purchase")}</OverlayWindow> } });
                    setPurchaseStatus(true);
                    await deleteStudent(TableType.Accepted, courseid!, user?.uid!);
                }}
            />
            <div className="login-title lineText">o</div>
            <div style={{ "padding": "0 15px", "display": "flex", "flexDirection": "column", "justifyContent": "center" }}>
                <div style={{ "display": "flex", "flexDirection": "column", "justifyContent": "center", "alignItems": "center" }}>
                    <h3>{t("Bank Data")}</h3>
                    <div style={{ "background": "#EBF2FC", "borderRadius": "10px", "padding": "15px", "marginBottom": "20px" }}>
                        <div style={{ "display": "flex", "gap": "5px" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Name: ")}</div>
                            <div>Centro de Diagnostico Prenatal Avanzado y Cirugía Fetal, Medicina Fetal México SA de Cv</div>
                        </div>
                        <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Address: ")}</div>
                            <div>Av Constituyentes 302 Torre 3 Piso 19. Colonia el Jacal CP 76 187, Querétaro</div>
                        </div>
                        <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                            <div style={{ "fontWeight": "700" }}>RFC: </div>
                            <div>MFQ1703062G6</div>
                        </div>
                        <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Bank name:")}</div>
                            <div>BBVA Bancomer</div>
                        </div>
                        <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Account: ")}</div>
                            <div>0117027826</div>
                        </div>
                        <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                            <div style={{ "fontWeight": "700" }}>Clabe: </div>
                            <div>012680001170278263</div>
                        </div>
                        <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Swift Code:")}</div>
                            <div>BCMRMXMMPYM</div>
                        </div>
                        <div style={{ "display": "flex", "gap": "5px", "marginTop": "5px" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Email: ")}</div>
                            <div>administracion@medicinafetalmexico.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;