import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { useLanguage } from '../context/LanguageProvider';
import { getCourseField } from './common/scripts/fields';
import { auth } from './config/firebaseSetup';

type props = {
    refreshToken: () => Promise<string | undefined>
}

function Play(props: props) {
    const { refreshToken } = props;
    const { courseid, levelindex, caseindex } = useParams();
    const [ unityWebAppUri, setUnityWebAppUri ] =  useState("");
    const [ done, setDone ] = useState(false);
    const { user } = useAuth();
    const [ token, setToken ] = useState<string | undefined>();
    const [ language ] = useLanguage();
    
    const getUnityWebAppUri = async () => {
        setToken(await refreshToken());
        setUnityWebAppUri(await getCourseField("appdata", "endpoints", process.env.NODE_ENV));
        setDone(true)
    }

    useEffect(() => {
        if (!done) getUnityWebAppUri();
    }, [unityWebAppUri]);

    return ( 
        <>
            {auth && done && (
                <iframe 
                    src={unityWebAppUri + 
                        '?course=' + courseid + 
                        '&level=' + levelindex + 
                        '&case=' + caseindex + 
                        '&uid=' + user?.uid +
                        '&token=' + token +
                        '&language=' + language} 
                    style={{width: "100vw", height: "100vh", border: 0}}
                    title="MFM Unity"
                    allowFullScreen/>
            )}
        </>
     );
}

export default Play;