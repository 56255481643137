import { createUserWithEmailAndPassword, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, signInWithRedirect, signOut, User, UserCredential } from "firebase/auth";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { getUserData } from "../components/common/scripts/users";
import { auth, db } from "../components/config/firebaseSetup";

type props = {
    children: string | JSX.Element | React.ReactNode
}

type contextType = {
    login: (email: string, password: string) => Promise<UserCredential>,
    signup: (email: string, password: string) => Promise<UserCredential>,
    loginWithGoogle: () => Promise<void>,
    loginWithFacebook: () => Promise<void>,
    loginWithApple: () => Promise<void>,
    logout: () => Promise<void>,
    user: User | null,
    loading: boolean,
    lite: boolean,
    premium: boolean,
    admin: boolean
}

const authContext = createContext<contextType>({
    login: async () => await signInWithEmailAndPassword(auth,"",""),
    signup: async () => await signInWithEmailAndPassword(auth,"",""),
    loginWithGoogle: async () => {},
    loginWithFacebook: async () => {},
    loginWithApple: async () => {},
    logout: async () => {},
    user: null,
    loading: true,
    lite: false,
    premium: false,
    admin: false
});

export const useAuth = () => {
    return useContext(authContext);
}

function AuthProvider(props: props) {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [lite, setLite] = useState<boolean>(true);
    const [premium, setPremium] = useState<boolean>(true);
    const [admin, setAdmin] = useState<boolean>(true);

    const signup = async (email: string, password: string) => 
        await createUserWithEmailAndPassword(auth, email, password);

    const loginWithGoogle = async () => {
        const google = new GoogleAuthProvider();
        await signInWithRedirect(auth, google);
    }

    const loginWithFacebook = async () => {
        const facebook = new FacebookAuthProvider();
        await signInWithRedirect(auth, facebook);
    }

    const loginWithApple = async () => {
        const apple = new OAuthProvider("apple.com");
        await signInWithRedirect(auth, apple);
    }

    const login = async (email: string, password: string) => 
        await signInWithEmailAndPassword(auth, email, password);

    const logout = async () => 
        await signOut(auth);

    const downloadUserData = async (currentUser: User) => {
        const userData = await getUserData(currentUser.uid);
        setAdmin(userData.admin || false);
        setLoading(false);
    }

    useEffect(() => {
        onAuthStateChanged(auth, currentUser => {
            setUser(currentUser);
            if (currentUser) downloadUserData(currentUser);
            else {
                setAdmin(false);
                setLoading(false);
            }
        });
    }, []);

    return (
        <authContext.Provider value={{
            login, 
            signup, 
            loginWithGoogle, 
            loginWithFacebook, 
            loginWithApple, 
            logout, 
            user, 
            loading, 
            lite, 
            premium,
            admin
        }}
        >
            {props.children}
        </authContext.Provider>
    );
}

export default AuthProvider;