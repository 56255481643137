import axios from "axios";
import { GradeRecord } from "../Students";
import { apiUri } from "./endpoints";

export async function getPurchases(uid: string) : Promise<string[]>
{
  const response =
    await axios.get(apiUri + "/api/owned/" + uid)
  const ownedCourses = response.data;
  return ownedCourses;
}

export async function getCourseUsers(courseid: string, path: string) : Promise<string[]>
{
  const response =
    await axios.get(apiUri + "/students/" + path + "/" + courseid)
  const ownedCourses = response.data;
  return ownedCourses;
}

export async function getPurchaseStatus(table: string, courseid: string, uid: string) : Promise<boolean>
{
  console.log(`${apiUri}/students/${table}/${courseid}/${uid}`);
  const response =
    await axios.get(`${apiUri}/students/${table}/${courseid}/${uid}`)
  return response.data;
}

export async function addStudent(table: string, courseid: string, email: string, ) : Promise<GradeRecord>
{
    const response = await axios.put<GradeRecord>(`${apiUri}/students/${table}/${courseid}`, {email});
    return response.data;
}

export async function deleteStudent(table: string, courseid: string, uid: string) : Promise<void>
{
    await axios.delete(apiUri + "/students/" + table + "/" + courseid + "/" + uid);
}