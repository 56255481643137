import { Outlet, useNavigate } from 'react-router-dom';
import './styles/Header.css'
import './styles/Footer.css'
import Header from './Header';
import Footer from './Footer';
import Overlay from '../overlay/Overlay';
import { useOverlay } from '../../context/OverlayProvider';

export function Layout() {
  const navigate = useNavigate();
  const [{ content }] = useOverlay();

  return (
    <>
      <Header/>
      <Outlet />
      <Footer/>
      <Overlay>
        {content}
      </Overlay>
    </>
  );
}