import React, { useState, useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import ReactSelect from "react-select";
import { useAuth } from "../../context/AuthProvider";
import RSOption from "../custom/RSOption";
import './styles/StudentsTable.css'
import { useTranslation } from 'react-i18next';
import i18n from '../../context/LanguageProvider'
import { useLanguage } from "../../context/LanguageProvider";

type CustomProps = {
  searchBar?: boolean,
  logOut: () => Promise<void>,
  navigate: NavigateFunction,
  callLogin?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UpperBar(props: CustomProps) {
  const { user } = useAuth();
  const [lang, changeLanguage] = useLanguage();
  const { t } = useTranslation();

  function handleLanguage(e: { value: string; label: JSX.Element; } | null) {
    if (e) {
      changeLanguage(e.value);
    }

  }
  return (
    <div className='bar' style={{ backgroundColor: "#011a5d" }}>
      <img className='logo' src={process.env.PUBLIC_URL + '/img/mfm-white.png'} alt="MFM Logo" onClick={() => props.navigate("/")}></img>
      <div style={{ "flex": "1 2" }}>
        <div className="menu-text">
          {/*<a style={{"color": "white", "fontWeight": "700", "textDecoration": "none"}} href="https://www.virtualcampusmfm.com/play/cGCNVVcPQ4fZag0yw1om/0/0" target="_blank">{t("Demo")}</a>*/}
          <a style={{ "color": "white", "fontWeight": "700", "textDecoration": "none" }} href="/information">{t("Courses")}</a>
          <a style={{ "color": "white", "fontWeight": "700", "textDecoration": "none" }} href="/news">{t("News")}</a>
          <a style={{ "color": "white", "fontWeight": "700", "textDecoration": "none" }} href="/support">{t("Tecnical Support")}</a>

        </div>
      </div>
      <ReactSelect
        styles={customStyles}
        defaultValue={languages.find(language => language.value === lang) || languages[0]}
        options={languages}
        onChange={handleLanguage} />
      <div className="dropdown" >
        <img className='accountIcon' src={process.env.PUBLIC_URL + '/img/accountIcon.png'} alt="Account Icon"></img>
        <div className="dropdown-content">
          <p style={logoutStyle} onClick={user ? props.logOut : (() => props.callLogin!(true))}>{user ? "Logout" : "Login"}</p>
        </div>
      </div>
    </div>
  );
}

const languages = [
  { value: "en", label: <RSOption title="English" src={process.env.PUBLIC_URL + "/img/us.svg"} /> },
  { value: "es", label: <RSOption title="Español" src={process.env.PUBLIC_URL + "/img/mx.svg"} /> }
]

const logoutStyle = {
  cursor: "pointer"
}

const customStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: state.selectProps.width,
    backgroundColor: "#ffffff6b",
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? 'black' : 'black',
    backgroundColor: state.isSelected ? '' : '',
    "&:hover": {
      backgroundColor: "#d8d8d8"
    },
    display: "flex",
    alignItems: "center",
    gap: 5
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 150,
    display: 'flex',
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      color: "white",
      display: 'flex',
      alignItems: 'center',
      gap: 5
    };
  }
}