import axios from "axios";
import { apiUri } from "./endpoints";

export async function getGrades(courseid: string) : Promise<string>
{
  const response =
    await axios.get(apiUri + "/api/grades/" + courseid)
  const json = response.data;
  return json;
}

export async function getUserGrades(courseid: string, uid: string) : Promise<string>
{
  const response =
    await axios.get(apiUri + "/api/studentgrades/" + courseid + "/" + uid)
  const json = response.data;
  return json;
}

export type Grade = {
    grade: number,
    id: string
}