import Case from './Case';
import { useEffect, useState } from 'react';
import * as Courses from './scripts/courses'
import { setModuleVisibility, setModuleTitle } from './scripts/courses';
import "./styles/Module.css"
import { User } from './scripts/users';
import { useAuth } from '../../context/AuthProvider';

type props = {
    level: Courses.Level;
    index: number;
    levelIndex: number;
    courseId: string;
    isPurchased: boolean;
    moduleGrades: any;
    bussy: boolean;
    setBussy: React.Dispatch<React.SetStateAction<boolean>>;
    removeModule: (levelIndex: number) => Promise<void>;
    removeCase: (levelIndex: number, caseIndex: number) => Promise<void>
    addCase: (levelIndex: number) => Promise<void>;
}

function Module(props: props) {
    let { level, index, levelIndex, courseId, isPurchased, moduleGrades, bussy, setBussy, removeModule, removeCase, addCase } = props;
    const [casesState, setCaseState] = useState('hide');
    const [progressBarState, setBarState] = useState('hide');
    const [dropdownState, setDropState] = useState('dropdown-closed');
    const [visible, setVisible] = useState(false);
    const {admin} = useAuth();
    const dropdownAction = () => {
        setCaseState((casesState) => (casesState === "hide" ? "cases" : "hide"));
        setBarState((progressBarState) => (progressBarState === "hide" ? "module-footer" : "hide"));
        setDropState((dropdownState) => (dropdownState === "dropdown-open" ? "dropdown-closed" : "dropdown-open"));
    }
    const setVisibleModule = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (!bussy)
            {
                setBussy(true);
                setVisible(e.target.checked);
                await setModuleVisibility(courseId || "", levelIndex, e.target.checked);
                setBussy(false);
            }
        } catch (error) {
            setBussy(false);
            console.log(error);
        }
    }
    const setTitle = async (e: React.FocusEvent<HTMLDivElement, Element>) => {
        try {
            if (!bussy)
            {
                setBussy(true);
                await setModuleTitle(courseId || "", levelIndex, e.target.innerText)
                setBussy(false);
            }
        } catch (error) {
            setBussy(false);
            console.log(error);
        }
    }
    useEffect(() => {
        setVisible(level?.visible || false);
    }, [level]);
    return (
        <>
                        {level && (
                <div className='module' key={"module-" + levelIndex}>
                    <div className='module-container'>
                        <div className='module-content'>
                            <div className='module-header'>
                                <div className='module-info' onClick={() => { if(!admin) dropdownAction() }}>
                                    <div className='index' onClick={() => { if(admin) dropdownAction() }}>{index}</div>
                                    <div 
                                        className='h1' 
                                        contentEditable={admin && !bussy} 
                                        placeholder="Modulo" 
                                        onBlur={setTitle}
                                        suppressContentEditableWarning>
                                            {level.title}
                                    </div>
                                </div>
                                {admin && (
                                    <>
                                        <input type="checkbox" className="module-checkbox" checked={visible} onChange={setVisibleModule}></input>
                                        <img src={process.env.PUBLIC_URL + '/img/delete.png'} width="30px" onClick={() => removeModule(levelIndex)} ></img>
                                    </>

                                )}
                                <img className={dropdownState} src={process.env.PUBLIC_URL + '/img/dropdown.png'} alt="Arrow" onClick={dropdownAction}></img>

                            </div>
                            <div className={casesState}>
                                {level.cases.map((case_, caseIndex) => {
                                    if (case_) return (<Case
                                        case_={case_}
                                        isPurchased={isPurchased}
                                        courseId={courseId}
                                        levelIndex={levelIndex}
                                        caseIndex={caseIndex}
                                        caseGrade={moduleGrades ? moduleGrades[caseIndex] : "-"}
                                        bussy={bussy}
                                        setBussy={setBussy}
                                        removeCase={removeCase} />)
                                    else return (<></>);
                                })}
                                {admin && (
                                    <div className='add-button add-case' onClick={() => addCase(levelIndex)}>
                                        <img src={process.env.PUBLIC_URL + '/img/add_icon.png'} alt="add" width="36.67px" height="36.67px"></img>
                                        <div className='add-module-items'>Añadir nuevo caso</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={progressBarState}></div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Module;