import axios from "axios";
import { HeadQuarter, TextBlock } from "./courses";
import { apiUri } from "./endpoints";

export async function setCourseDescription(courseid: string, value: string) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/api/courses/" + courseid + "/description", { value });
  return response.statusText;
}

export async function setCourseField(courseid: string, field: string, value: string) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/api/courses/" + courseid + "/" + field, { value });
  return response.statusText;
}

export async function setCourseBooleanField(courseid: string, field: string, value: boolean) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/api/courses/" + courseid + "/" + field, { value });
  return response.statusText;
}

export async function setCourseNumericField(courseid: string, field: string, value: number) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/api/courses/" + courseid + "/" + field, { value });
  return response.statusText;
}

export async function setCourseTextBlocksField(courseid: string, value: TextBlock[]) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/api/courses/" + courseid + "/descriptionBlocks", { value });
  return response.statusText;
}

export async function setCourseHQs(courseid: string, value: HeadQuarter[]) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/api/courses/" + courseid + "/headquarters", { value });
  return response.statusText;
}

export async function setCourseStartDate(courseid: string, date: Date) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/dates/" + courseid + "/start_date", { value: date.getTime() });
  return response.statusText;
}

export async function setCourseEndDate(courseid: string, date: Date) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/dates/" + courseid + "/end_date", { value: date.getTime() });
  return response.statusText;
}

export async function getCourseField(collection: string, document: string, field: string) : Promise<string>
{
  const response =
    await axios.get(apiUri + "/fields/" + collection + "/" + document + "/" + field);
  return response.data;
}