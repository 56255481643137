import axios from "axios";
import { apiUri } from "./endpoints";

export async function getProfessors() : Promise<Professor[]>
{
  const response =
    await axios.get(apiUri + "/api/professors")
  const professors = response.data;
  return professors;
}

export async function addProfessorOnCourse(courseid: string, id: string) : Promise<void>
{
  console.log(id);
  await axios.put(apiUri + "/api/addprofessoroncourse/" + courseid, {id});
}

export async function deleteProfessorFromCourse(courseid: string, id: string) : Promise<void>
{
  await axios.delete(apiUri + "/api/removeprofessoroncourse/" + courseid + "/" + id);
}

export async function deleteProfessor(id : string) : Promise<void>
{
  await axios.delete(apiUri + "/api/professors/" + id);
}

export async function createProfessor(professor: Professor) : Promise<string>
{
  const response = await axios.post(apiUri + "/api/professors", professor);
  return response.data;
}

export async function setProfessorField(id: string, field: string, value: string) : Promise<string>
{
  const response =
    await axios.put(apiUri + "/api/professors/" + id + "/" + field, { value });
  return response.statusText;
}

export type Professor = {
  name: string;
  sinapsis: string;
  id: string;
  thumbnail: string;
  special: string;
};