import axios from "axios";
import { apiUri } from "./endpoints";

export async function getUserData(uid: string) : Promise<User>
{
  const response =
    await axios.get(apiUri + "/api/users/" + uid)
  const user = response.data;
  return user;
}

export async function getUsersData(uids: string[]) : Promise<User[]>
{
  const json = JSON.parse("[]");
  for(var i in uids)
  {
    json[i] = JSON.parse("{}");
    json[i].uid = uids[i];
  }
  const response =
    await axios.post(apiUri + "/api/names/", json)
  const users = response.data;
  return users;
}

export type User = {
  uid: string;
  displayName: string;
  email: string;
  admin: boolean;
};