import React from 'react'
import './styles/LoadingScreen.css'

function LoadingScreen() {
    return ( 
        <div className='loading-background'>
            <img width="128px" src={process.env.PUBLIC_URL + '/img/mfm-load.png'} className='animated-logo' alt="Loading"></img>
        </div>
     );
}

export default LoadingScreen;