import React from "react";
import { useOverlay } from "../../context/OverlayProvider";
import "./styles/Overlay.css"

type props = {
    children: string | JSX.Element | React.ReactNode
}

const Overlay = React.forwardRef((props: props, ref: React.Ref<HTMLDivElement>) => {
    const [{ display }, setOverlay] = useOverlay();
    return (
        <div className='overlay-background' style={{display}} >
            <div className='overlay-background' style={{"position": "absolute", "zIndex": "-1", "opacity": "0", "cursor": "pointer"}} onClick={() => setOverlay(overlay => {return {...overlay, display: "none"}})}/>
            {props.children}
        </div>
    )
});

export default Overlay;