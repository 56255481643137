import { User } from './scripts/users';
import * as Courses from './scripts/courses';
import { useNavigate } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import { setCaseSpawn, setCaseTitle } from './scripts/courses';
import { useAuth } from '../../context/AuthProvider';

type props = {
    case_: Courses.Case;
    isPurchased: boolean;
    courseId: string;
    levelIndex: number;
    caseIndex: number;
    caseGrade: string;
    removeCase: (levelIndex: number, caseIndex: number) => Promise<void>;
    bussy: boolean;
    setBussy: React.Dispatch<React.SetStateAction<boolean>>
}

function Case(props: props) {
    const { case_, courseId, levelIndex, caseIndex, isPurchased, caseGrade, removeCase, bussy, setBussy} = props;
    const navigate = useNavigate();
    const spawnSelector = useRef<HTMLSelectElement>(null);
    const { admin } = useAuth();

    useEffect(() => {
        spawnSelector.current ? spawnSelector.current.selectedIndex = case_?.spawn || 0 : console.log("no ha cargado");
    }, [case_])

    const clickHandler = (courseId: string, levelIndex: number, caseIndex: number) =>  {
        if (isPurchased || admin) navigate('/play/' + courseId +
            '/' + levelIndex +
            '/' + caseIndex);
        else window.alert("El curso actual no se encuentra en tus cursos adquiridos. Compralo o habla con un administrador.");
    };

    const setSpawn = async(e: React.ChangeEvent<HTMLSelectElement>) => {
        setCaseSpawn(courseId, levelIndex, caseIndex, e.target.selectedIndex)
    }

    const setTitle = async (e: React.FocusEvent<HTMLDivElement, Element>) => {
        try {
            if (!bussy)
            {
                setBussy(true);
                await setCaseTitle(courseId || "", levelIndex, caseIndex, e.target.innerText)
                setBussy(false);
            }
        } catch (error) {
            setBussy(false);
            console.log(error);
        }
    }

    return ( 
        <div className='case' key={levelIndex + "-" + caseIndex}>
            <img className='play-button' src={process.env.PUBLIC_URL + '/img/play-case-black.png'} onClick={() => clickHandler(courseId, levelIndex, caseIndex)} alt="Play"></img>
            <div 
                className='h2'
                contentEditable={admin && !bussy}
                placeholder="Caso"
                suppressContentEditableWarning
                onBlur={setTitle}>{case_?.title}</div>
            <div className='button-container'>
                {admin ? (
                    <div className='case-edit-container'>
                        <div>Comenzar en:</div>
                        <div className='case-dropdown'>
                            <select ref={spawnSelector} onChange={setSpawn}>
                                <option>Recepción</option>
                                <option>Sala de juntas</option>
                                <option>Quirófano</option>
                                <option>Neurodesarrollo</option>
                                <option>Computo</option>
                                <option>Investigación</option>
                                <option>Aula</option>
                                <option>Ecocardiograma 1</option>
                                <option>Ecocardiograma 2</option>
                                <option>Ecocardiograma 3</option>
                                <option>Auditorio</option>
                            </select>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/img/delete.png'} width="30px" height="30px" onClick={() => removeCase(levelIndex, caseIndex)}></img>
                    </div>
                ) : (<div className='score'>{caseGrade !== undefined ? caseGrade : "-"}</div>)}

            </div>
        </div>
    );
}

export default Case;