import axios from "axios";
import { doc, getDoc, Timestamp } from "firebase/firestore"
import { db } from "../../config/firebaseSetup";
import { apiUri } from "./endpoints";

export async function getCourses() : Promise<CourseSummary[]>
{
  const response =
    await axios.get(apiUri + "/api/courses")
  const courses = response.data;
  return courses;
}

export async function getCourse(id: string) : Promise<Course>
{
  const course = await getDoc(doc(db, "courses", id))
  return course.data() as Course;
}

export async function postCourse(course: Course) : Promise<string>
{
  const response = 
    await axios.post(apiUri + "/api/courses/", course);
  return response.data;
}

export async function postModule(courseid: string, level: Level) : Promise<void>
{
  await axios.post(apiUri + "/api/courses/" + courseid + "/levels", level);
}

export async function postCase(courseid: string, levelIndex: number, case_: Case) : Promise<void>
{
  await axios.post(apiUri + "/api/courses/" + courseid + "/" + levelIndex + "/cases", case_);
}

export async function deleteCourse(courseid: string) : Promise<void>
{
  let config = {
    headers: {
      uid: sessionStorage.getItem('uid') || "",
    }
  }
  await axios.delete(apiUri + "/api/courses/" + courseid, config);
}
export async function duplicateCourseOnAPI(courseid: string) : Promise<void>
{
  let config = {
    headers: {
      uid: sessionStorage.getItem('uid') || "",
    }
  }
  await axios.post(apiUri + "/duplicate/courses/" + courseid, config);
}

export async function deleteModule(courseid: string, levelIndex: number) : Promise<void>
{
  await axios.delete(apiUri + "/api/courses/" + courseid + "/" + levelIndex);
}
export async function deleteCase(courseid: string, levelIndex: number, caseIndex: number) : Promise<void>
{
  await axios.delete(apiUri + "/api/courses/" + courseid + "/" + levelIndex + "/" + caseIndex);
}

export async function setCaseSpawn(courseid: string, levelIndex: number, caseIndex: number, value: number) : Promise<void>
{
  await axios.put(apiUri + "/api/courses/" + courseid + "/" + levelIndex + "/" + caseIndex + "/spawn", {value});
}

export async function setModuleVisibility(courseid: string, levelIndex: number, value: boolean) : Promise<void>
{
  await axios.put(apiUri + "/levels/" + courseid + "/" + levelIndex + "/visible", {value});
}

export async function setModuleTitle(courseid: string, levelIndex: number, value: string) : Promise<void>
{
  await axios.put(apiUri + "/levels/" + courseid + "/" + levelIndex + "/title", {value});
}

export async function setCaseTitle(courseid: string, levelIndex: number, caseIndex: number, value: string) : Promise<void>
{
  await axios.put(apiUri + "/api/courses/" + courseid + "/" + levelIndex + "/" + caseIndex + "/title", {value});
}

export const blockTitleOptions = [
  "Objetivos",
  "Dirigido a",
  "Competencias adquiridas",
  "Sede del curso",
  "Aval universitario",
  "Asistencia al diplomado",
  "Justificación",
  "Titulo obtenido",
  "Programa educativo",
  "Preinscripción",
  "Temario"
]

export type CourseSummary = {
  id: string;
  thumbnail: string;
  videoThumbnail: string;
  title: string;
  published: boolean;
  enabled?: boolean;
  time: number;
  studentCount: number;
  start_date: Timestamp;
  end_date: Timestamp;
};

export type Course = {
  description: string;
  thumbnail: string;
  banner?: string;
  videoThumbnail: string;
  video: string;
  signupForm: string;
  title: string;
  subtitle: string;
  programUrl?: string;
  professors: string[];
  published: boolean;
  enabled?: boolean
  price: number;
  currency: number;
  time: number;
  teachersCount: number;
  studentCount: number;
  start_date: Timestamp;
  end_date: Timestamp;
  levels: Level[];
  headquarters?: HeadQuarter[],
  descriptionBlocks: TextBlock[] | undefined
};

export interface HeadQuarter {
  name?: string,
  address?: string
  entity: number,
  country: string,
  location?: string
  attendances: Attendance[]
}

interface Attendance {
  title?: string,
  start: Timestamp,
  end: Timestamp
}

export type TextBlock = {
  id: number;
  text: string
}

export type Level = {
  title: string;
  visible: boolean;
  cases: Case[];
} | null;

export type Case = {
  title: string;
  spawn: number;
} | null;