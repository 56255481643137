import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import AuthProvider from './context/AuthProvider';
import OverlayProvider from './context/OverlayProvider';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import LanguageProvider from './context/LanguageProvider';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <LanguageProvider>
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_CLIENT_ID || "", "currency": "MXN", "vault": true }}>
          <OverlayProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </OverlayProvider>
        </PayPalScriptProvider>
      </LanguageProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
<script src="//code.tidio.co/f5tl0cuylu6gqa9owqmjrscuvgwnbcc5.js" async></script>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();