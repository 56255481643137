import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCupBzpvUaq5afVhqhmZ7orBcBdf66i5As",
  authDomain: "archfuve.firebaseapp.com",
  databaseURL: "https://archfuve.firebaseio.com",
  projectId: "archfuve",
  storageBucket: "archfuve.appspot.com",
  messagingSenderId: "932289624891",
  appId: "1:932289624891:web:f0a1461cf8a575f3dc951e"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);