import './styles/Students.css';
import Table from "./StudentsTable";
import { useMemo, useEffect, useState } from 'react';
import { Course } from './scripts/courses';
import { User, getUsersData } from './scripts/users';
import { useParams } from 'react-router-dom';
import { getGrades } from './scripts/grades';
import { getCourseUsers, deleteStudent, addStudent } from './scripts/purchases';
import * as XLSX from 'xlsx';
import { useAuth } from '../../context/AuthProvider';

export enum TableType { 
    Students = "purchases", 
    Applicants = "applicants", 
    Accepted = "admitted"
} 

type props = {
    course: Course | null,
    type: TableType,
    bussy: boolean,
    gradeList: GradeRecord[] | null,
    setBussy: React.Dispatch<React.SetStateAction<boolean>>,
    setGradeList: React.Dispatch<React.SetStateAction<GradeRecord[] | null>>,
    showAdder?: () => void
}

export type GradeRecord = {
    uid: string, 
    displayName: string, 
    email: string
    grades: any;
}

type Grade = {
    grade: number,
    id: string
}

function Students(props: props) {
    const { courseid } = useParams();
    const { course, type, bussy, setBussy, showAdder, gradeList, setGradeList } = props;
    //const [done, setDone] = useState(false);
    const [search, setSearch] = useState("");
    const {admin} = useAuth();
    let userData: User[] = [];
    useEffect(() => {
        if (admin) {
            const fetch = async () => {
                if (courseid) {
                    const uids = await getCourseUsers(courseid, type);
                    let grades = "";
                    if (type === TableType.Students) grades = await getGrades(courseid || "");
                    getUserDataByPage(uids);
                    waitForNames(uids, grades);
                    //setDone(true);
                }
            }
            if (!gradeList) fetch();
        }
    })
    const exportToExcel = (type: XLSX.BookType, fn: string) => {
        var elt = document.getElementById('students-grade-table');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Alumnos" });
        return XLSX.writeFile(wb, fn + "." + (type || 'xlsx'));
     }
    const waitForNames = (uids: string[], grades: string) => {
        if (userData?.length !== uids.length) window.setTimeout(function () { waitForNames(uids, grades) }, 50);
        else {
            setGradeList(getGradeList(userData, grades));
        }
    }
    const getGradeList = (users: User[], gradesJson: any) => {
        const tempGradeList: GradeRecord[] = [];
        users.forEach(user => {
            let studentGrades = {};
            if (type === TableType.Students) studentGrades = getStudentGradeRecord(user.uid, gradesJson);
            tempGradeList.push({uid: user.uid, displayName: user.displayName, email: user.email, grades: studentGrades})
        });
        return tempGradeList;
    }
    const getStudentGradeRecord = (uid: string, gradesJson: any) => {
        let studentGrades = JSON.parse("{}");
        for(var levelIndex in gradesJson)
        {
            studentGrades[levelIndex] = JSON.parse("{}");
            for(var caseIndex in gradesJson[levelIndex])
            {
                const caseGrades: Grade[] = gradesJson[levelIndex][caseIndex];
                studentGrades[levelIndex][caseIndex] = "";
                for(var i in caseGrades)
                    if (caseGrades[i].id === uid)
                    {
                        studentGrades[levelIndex][caseIndex] = caseGrades[i].grade;
                        break;
                    }
            }
        }
        return studentGrades;
    }
    const getUserDataByPage = async (uids: string[]) =>
    {
        const uidsPages = createUserGroups(uids);
        for(var i in uidsPages){
            setUsersDataOnFinish(await getUsersData(uidsPages[i]), i);
        }
    }
    const setUsersDataOnFinish = (userPage: User[], pageNumber: string) => {
        userData = userData.concat(userPage);
    }
    const removeUser = async (email: string) => {
        try {
            if (!bussy)
            {
                setBussy(true);
                const userRecord = gradeList?.filter(record => record.email === email)[0];
                await deleteStudent(type, courseid || "", userRecord?.uid || "");
                setGradeList(gradeList?.filter(record => record.email !== email) || []);
                setBussy(false);
            }
            
        } catch (error) {
            console.log(error);
        }
    }
    const acceptUser = async (email: string) => {
        try {
            if (!bussy && type !== TableType.Students)
            {
                setBussy(true);
                const userRecord = gradeList?.filter(record => record.email === email)[0];
                let targetCollection = "";
                if (type === TableType.Applicants) targetCollection = TableType.Accepted;
                else if (type === TableType.Accepted) targetCollection = TableType.Students;
                await addStudent(targetCollection, courseid!, email);
                await deleteStudent(type, courseid || "", userRecord?.uid || "");
                setGradeList(gradeList?.filter(record => record.email !== email) || []);
                setBussy(false);
            }
            
        } catch (error) {
            console.log(error);
        }
    }
    const columns = useMemo(
        () => [
            {
                // first group - TV Show
                Header: "info",
                // First group columns
                columns: [
                  {
                    Header: "Nombre",
                    accessor: "displayName"
                  },
                  {
                    Header: "Correo electrónico",
                    accessor: "email"
                  }
                ]
            }
    ],[]);
    let levelCount = 0;
    course?.levels.forEach((level, levelIndex) => {
        if(level != null)
        {
            levelCount++;
            var subColumns: {Header: string, accessor: string}[] = [];
            level.cases.forEach((case_, caseIndex) => {
                if (case_ != null) subColumns.push({
                    Header: case_.title,
                    accessor: "grades." + levelIndex + "." + caseIndex
                });
            });
            if (type === TableType.Students) columns.push({
                Header: levelCount + ". " + level.title,
                columns: subColumns
            });
        }
    })
    return ( 
        <div className="students-area">
            <div className="students-area-top">
                <div className='students-search-bar-area'>
                    <input type="text" className='students-search-bar' onChange={(e) => setSearch(e.target.value)} value={search}></input>
                    <button className='students-search-button'>
                        <img className='students-search-logo' src={process.env.PUBLIC_URL + '/img/search-white.png'} alt="searchIcon"/>
                    </button>
                </div>
                {type === TableType.Students && (
                    <button style={blueButtonStyle} className='blue-button-a' onClick={showAdder}>
                        Agregar usuario
                    </button>
                )}
                <button style={blueButtonStyle} className='blue-button-a' onClick={() => exportToExcel('xlsx', course?.title || "Calificaciones")}>
                    Descargar
                </button>
            </div>
            <div className='students-table-area'>
                <div className='student-table-container'>
                    <Table 
                        columns={columns} 
                        data={gradeList?.filter(record => 
                            record.displayName?.toLowerCase().includes(search.toLowerCase()) || 
                            record.email?.toLowerCase().includes(search.toLowerCase())) || data} 
                        removeUser={removeUser}
                        acceptUser={acceptUser}
                        type={type}/>
                </div>
            </div>
        </div>
     );
}

function createUserGroups(uids: string[]) {
    const groupCount = Math.ceil(uids.length / 100);
    return new Array(groupCount)
      .fill('')
      .map((_, i) => uids.slice(i * 100, (i + 1) * 100));
  }

const blueButtonStyle = {
    height: "35px",
    width: "240px"
}

  const data = [
      {}
  ];

export default Students;