import { createContext, useContext, useState } from "react";

type props = {
    children: string | JSX.Element | React.ReactNode
}

type LanguageContextType = [string, (language: string) => void]

const languageContext = createContext<LanguageContextType>(["en", () => null]);

export const useLanguage = () => {
    return useContext(languageContext);
}

function LanguageProvider(props: props) {
    const [language, setLanguage] = useState<string>(window.localStorage.getItem("language") ||  window.navigator.languages[2]);

    const changeLanguage = (language: string) => {
        setLanguage(language);
        window.localStorage.setItem("language", language);
    }

    return (
        <languageContext.Provider value={[language, changeLanguage]}>
            {props.children}
        </languageContext.Provider>
    );
}

export default LanguageProvider;