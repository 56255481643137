import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import LoadingScreen from '../common/LoadingScreen';

type props = {
    children: JSX.Element
}

export function RequireAuth(props: props) {
  const location = useLocation();
  const { user, loading } = useAuth();
  if (loading) return <LoadingScreen/>
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return props.children;
}